import {
    AxisScrollStrategies,
    AxisTickStrategies,
    ColorCSS,
    ColorHEX,
    ColorRGBA,
    customTheme,
    emptyFill,
    emptyLine,
    emptyTick,
    LegendBoxBuilders,
    lightningChart,
    MouseStyles,
    SolidFill,
    SolidLine,
    Themes,
    UIDraggingModes,
    UILayoutBuilders,
    UIOrigins,
} from '@arction/lcjs';
import pixelWidth from 'string-pixel-width';
import { chartHelper } from './helpers';

const arctionHelper = {

    applyAxisSettings(chart, axisSettings) {
        const {
            xAxisSettings,
            yAxisSettings,
            autoFitXDisabled,
            autoFitYDisabled,
            autoFitYDisabledKeys,
        } = axisSettings;

        if (autoFitXDisabled && autoFitYDisabled) return;

        const axisRanges = {};
        const series = chart.getSeries();
        let minX, maxX;
        series.forEach(s => {
            minX = s.getXMin();
            maxX = s.getXMax();
            const y = s.axisY.getTitle();
            const min = s.getYMin();
            const max = s.getYMax();
            // minX = s.axisX.getXMin()
            // maxX = s.axisX.getXMax()

            if (!axisRanges[y]) {
                axisRanges[y] = { min: 0, max: 0 };
            }

            const currentMin = axisRanges[y]['min'];
            const currentMax = axisRanges[y]['max'];

            axisRanges[y] = {
                min: !currentMin || currentMin > min ? min : currentMin,
                max: !currentMax || currentMax < max ? max : currentMax,
            };
        });

        if (xAxisSettings?.count) {
            const series = chart.getSeries();
            if (series.length) {
                maxX = series[0].getXMax();

                const diff = chartHelper.getTimeInMilliseconds(xAxisSettings);
                minX = maxX - diff;
                chart
                    .getDefaultAxisX()
                    .stop()
                    .setScrollStrategy(AxisScrollStrategies.progressive)
                    .setInterval(minX, maxX + (maxX - minX) * 0.03);
            }

        }
        else {
            chart.getDefaultAxisX().setInterval(minX, maxX + (maxX - minX) * 0.03);
            chart.getDefaultAxisX().setScrollStrategy(AxisScrollStrategies.expansion);
        }


        chart.forEachAxisY(axis => {
            const axisTitle = axis.getTitle();
            const axisSettings = yAxisSettings.find(s => (
                `[${s.unit}]` === axisTitle),
            );
            if (axisSettings) {
                const { min, max } = axisSettings;

                if (min !== undefined && max !== undefined) {
                    if (autoFitYDisabledKeys && autoFitYDisabledKeys[axisTitle]) {
                        // TODO: need to test this case
                    } else {
                        axis.setScrollStrategy(undefined).setInterval(min, max);
                    }
                }
                else {
                    if (autoFitYDisabledKeys && autoFitYDisabledKeys[axisTitle]) {
                        // TODO: need to test this case
                    } else {
                        axis.setScrollStrategy(AxisScrollStrategies.expansion);
                        if (axisRanges[axisTitle]) {
                            const { min, max } = axisRanges[axisTitle];
                            const minWithPadding = min - 0.1 * (max - min) - 0.005;
                            const maxWithPadding = max + 0.1 * (max - min) + 0.005;
                            axis.setInterval(minWithPadding, maxWithPadding, 100);
                        }
                    }
                }
            }
        });

    },

    setAxisIntervals(chart) {
        if (!chart) return;

        const axisRanges = {};
        const series = chart.getSeries();
        let minX, maxX;
        series.forEach(s => {
            minX = s.getXMin();
            maxX = s.getXMax();
            const y = s.axisY.getTitle();
            const min = s.getYMin();
            const max = s.getYMax();
            // minX = s.axisX.getXMin()
            // maxX = s.axisX.getXMax()

            if (!axisRanges[y]) {
                axisRanges[y] = { min: 0, max: 0 };
            }

            const currentMin = axisRanges[y]['min'];
            const currentMax = axisRanges[y]['max'];

            axisRanges[y] = {
                min: !currentMin || currentMin > min ? min : currentMin,
                max: !currentMax || currentMax < max ? max : currentMax,
            };
        });

        chart.forEachAxisY(axis => {
            axis.setScrollStrategy(AxisScrollStrategies.expansion);
            const axisTitle = axis.getTitle();
            if (axisRanges[axisTitle]) {
                const { min, max } = axisRanges[axisTitle];
                const minWithPadding = min - 0.1 * (max - min) - 0.005;
                const maxWithPadding = max + 0.1 * (max - min) + 0.005;
                axis.setInterval(minWithPadding, maxWithPadding, 100);
            }
        });

        chart.getDefaultAxisX().setInterval(minX, maxX + maxX * 0.03);
        chart.getDefaultAxisX().setScrollStrategy(AxisScrollStrategies.expansion);
        // const { start, end } = chart.getDefaultAxisX().getInterval();
    },

    changeDateTimeFormat(chart, cb) {
        if (!chart) return;

        chart
            .getDefaultAxisX()
            .setTickStrategy(AxisTickStrategies.DateTime, tickStrategy =>
                tickStrategy
                    .setFormattingHour(cb, cb, cb)
                    .setFormattingMinute(cb, cb, cb)
                    .setFormattingSecond(cb, cb, cb)
                    .setFormattingMilliSecond(cb, cb)
                    .setCursorFormatter(cb),
            );
    },

    createLegend(chart) {
        if (!chart) return null;
        const hiddenLegend = chart
            .addLegendBox(LegendBoxBuilders.VerticalLegendBox)
            .setTitle('')
            .setOrigin(UIOrigins.LeftTop)
            .setPosition({ x: 0, y: 0 });

        hiddenLegend.add(chart);
        hiddenLegend.setEntries((entry, component) => {
            const lineStyle = component.getStrokeStyle().getFillStyle();
            entry
                .setTextFillStyle(lineStyle)
                .setTextFont((font) => font.setStyle('normal'),
                    // .setWeight('bold'),
                );
        });
        return hiddenLegend;
    },

    createLegendLayout(chart) {
        if (!chart) return;

        return chart.addUIElement(UILayoutBuilders.Column)
            .setBackground(b => b.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
            .setOrigin(UIOrigins.LeftTop)
            .setPosition({ x: 0, y: 100 })
            .setPadding(0)
            .setMargin(0)
            .setDraggingMode(UIDraggingModes.notDraggable);
    },

    getLegendEntries(legend, chart) {
        const items = [];
        legend.setEntries((entry, component) => {
            if (component.chart !== chart) return;
            const { size, family } = entry.getTextFont();
            const entryWidth = pixelWidth(entry.getText(), { size, font: family.toLowerCase() });
            items.push({
                item: component,
                width: entryWidth + 20,
            });
            // items.push(entry)
        });

        return items;
    },

    getSeriesWithWidth(chart) {
        return chart.getSeries().map(s => {
            return ({
                item: s,
                width: pixelWidth(s.getName(), { size: 14, font: 'open sans' }) + 20,
            });
        });
    },

    getSeriesChunks(arr, max) {
        const chunks = [];

        let currentChunkWidth = 0;
        let currentStartIndex = 0;


        arr.forEach((value, currentIndex) => {
            const isLast = currentIndex === (arr.length - 1);

            if (value.width >= max) {
                chunks.push([value]);
                currentStartIndex += 1;
            } else {
                currentChunkWidth += value.width;
                if (!isLast) {
                    if ((currentChunkWidth + arr[currentIndex + 1].width) >= max) {
                        chunks.push(arr.slice(currentStartIndex, currentIndex + 1));
                        currentStartIndex = currentIndex + 1;
                        currentChunkWidth = 0;
                    }
                } else {
                    chunks.push(arr.slice(currentStartIndex));
                }
            }
        });
        return chunks;
    },

    legendEntriesChunks(arr, max) {
        const chunks = [];

        let currentChunkWidth = 0;
        let currentStartIndex = 0;


        arr.forEach((value, currentIndex) => {
            const isLast = currentIndex === (arr.length - 1);

            if (value.width >= max) {
                chunks.push([value]);
                currentStartIndex += 1;
            } else {
                currentChunkWidth += value.width;
                if (!isLast) {
                    if ((currentChunkWidth + arr[currentIndex + 1].width) >= max) {
                        chunks.push(arr.slice(currentStartIndex, currentIndex + 1));
                        currentStartIndex = currentIndex + 1;
                        currentChunkWidth = 0;
                    }
                } else {
                    chunks.push(arr.slice(currentStartIndex));
                }
            }
        });
        return chunks;
    },

    generateAdaptiveLegend(legendLayout, chart) {
        const items = this.getSeriesWithWidth(chart);
        let chartWidth = 400;
        try {
            const chartSize = chart.uiScale.getInnerIntervalPixels();
            chartWidth = chartSize.x - 120;
        } catch (e) {
            console.log(e);
        }

        const itemsChunks = this.getSeriesChunks(items, chartWidth);
        chart.setPadding({ top: itemsChunks.length * 30 });

        itemsChunks.forEach(chunk => {
            const rowLegend = legendLayout
                .addElement(LegendBoxBuilders.HorizontalLegendBox)
                .setTitle('')
                .setPadding(0)
                .setMargin(0);
            chunk.forEach(entry => {
                rowLegend.add(entry.item);
            });
            rowLegend.setEntries((entry, component) => {
                const lineStyle = component.getStrokeStyle().getFillStyle();
                entry
                    .setTextFillStyle(lineStyle)
                    .setTextFont((font) => font.setStyle('normal'),
                        // .setWeight('bold'),
                    );
            });
        });
    },
};


class ArctionDashboard {
    license = null;
    id = null;
    dashboard = null;
    chart = null;
    theme = 'Light';
    height = 450;
    dateOrigin = null;
    numericAxisLabelPadding = -7;
    dataReceiving = false;

    constructor(config) {
        const { id, theme, height, startTime, updateChartDateRange } = config;
        this.id = id;
        this.theme = theme;
        this.height = height;
        const licenceKeyConfig = process.env.REACT_APP_ARCTION_CHART_KEY || undefined;
        this.license = licenceKeyConfig === 'false' ? undefined : licenceKeyConfig;
        this.dateOrigin = startTime;
        this.initMouseWheelZoom();
        this.updateChartDateRange = updateChartDateRange;
        this.startInterval();
    }

    updateDataReceivingStatus(receiving) {
        this.dataReceiving = receiving;
        if (!receiving) {
            this.logEndPointAsUTC();
        } else {
            this.logCurrentXAxisInterval();
        }
    }

    startInterval() {
        this.intervalId = setInterval(() => {
            this.updateDataReceivingStatus(this.dataReceiving);
        }, 1000);
    }

    stopInterval() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    initMouseWheelZoom() {
        if (!this.chart) return;
        this.chart.setMouseInteractionWheelZoom(false);
        this.chart.engine.container.onwheel = (event) => {
            event.preventDefault();
            const mousePos = this.chart.engine.container.getBoundingClientRect();
            const mouseX = event.clientX - mousePos.left;
            const mouseY = event.clientY - mousePos.top;
            if (event.ctrlKey) {
                this.zoomYAxis(event.deltaY, mouseY);
            } else if (event.shiftKey) {
                this.zoomXAxis(event.deltaY, mouseX);
            } else {
                this.zoomBothAxes(event.deltaY, mouseX, mouseY);
            }
            this.logCurrentXAxisInterval();
        };
    }

    zoomXAxis(deltaY, mouseX) {
        const axisX = this.chart.getDefaultAxisX();
        const range = axisX.getInterval();
        const zoomFactor = deltaY > 0 ? 1.1 : 0.9; 
        const pixelWidth = this.chart.engine.container.clientWidth;
        const relativeMouseX = mouseX / pixelWidth;
        const dataRange = range.end - range.start;
        const newStart = range.start + (relativeMouseX * dataRange * (1 - zoomFactor));
        const newEnd = range.end - ((1 - relativeMouseX) * dataRange * (1 - zoomFactor));
        axisX.setInterval(newStart, newEnd, 0, true);
        this.logCurrentXAxisInterval();
    }

    zoomYAxis(deltaY, mouseY) {
        const pixelHeight = this.chart.engine.container.clientHeight;
        const zoomFactor = deltaY > 0 ? 1.1 : 0.9; 
        this.chart.forEachAxisY(axis => {
            const range = axis.getInterval();
            const relativeMouseY = 1 - (mouseY / pixelHeight);
            const dataRange = range.end - range.start;
            const newStart = range.start + (relativeMouseY * dataRange * (1 - zoomFactor));
            const newEnd = range.end - ((1 - relativeMouseY) * dataRange * (1 - zoomFactor));
            axis.setInterval(newStart, newEnd, 0, true);
        });
        this.logCurrentXAxisInterval();
    }

    zoomBothAxes(deltaY, mouseX, mouseY) {
        const zoomFactor = deltaY > 0 ? 1.1 : 0.9; 
        // Zoom X Axis
        const axisX = this.chart.getDefaultAxisX();
        const rangeX = axisX.getInterval();
        const pixelWidth = this.chart.engine.container.clientWidth;
        const relativeMouseX = mouseX / pixelWidth;
        const dataRangeX = rangeX.end - rangeX.start;
        const newStartX = rangeX.start + (relativeMouseX * dataRangeX * (1 - zoomFactor));
        const newEndX = rangeX.end - ((1 - relativeMouseX) * dataRangeX * (1 - zoomFactor));
        axisX.setInterval(newStartX, newEndX, 0, true);
        // Zoom Y Axes
        const pixelHeight = this.chart.engine.container.clientHeight;
        const relativeMouseY = 1 - (mouseY / pixelHeight);
        this.chart.forEachAxisY(axis => {
            const rangeY = axis.getInterval();
            const dataRangeY = rangeY.end - rangeY.start;
            const newStartY = rangeY.start + (relativeMouseY * dataRangeY * (1 - zoomFactor));
            const newEndY = rangeY.end - ((1 - relativeMouseY) * dataRangeY * (1 - zoomFactor));
            axis.setInterval(newStartY, newEndY, 0, true);
        });

        this.logCurrentXAxisInterval();
    }

    logCurrentXAxisInterval() {
        const axisX = this.chart.getDefaultAxisX();
        const interval = axisX.getInterval();
        const startDate = new Date(this.dateOrigin.getTime() + interval.start);
        const endDate = new Date(this.dateOrigin.getTime() + interval.end);
        if (this.updateChartDateRange) {
            this.updateChartDateRange(startDate, endDate);
        }
    }

    logEndPointAsUTC() {
        if (!this.chart) return;

        const series = this.chart.getSeries();
        const endPoints = series.map(s => s.getXMax()).filter(point => point !== null);

        if (endPoints.length > 0) {
            const maxEndPoint = Math.max(...endPoints);
            const endDateUTC = new Date(this.dateOrigin.getTime() + maxEndPoint);
            const axisX = this.chart.getDefaultAxisX();
            const interval = axisX.getInterval();
            const startDate = new Date(this.dateOrigin.getTime() + interval.start);
            if (this.updateChartDateRange) {
                this.updateChartDateRange(startDate, endDateUTC);
            }
        }
    }


    isDarkTheme() {
        return this.theme === 'Dark';
    }

    arctionTheme() {
        return this.isDarkTheme()
            ? this.arctionCustomDarkTheme()
            : this.arctionCustomLightTheme();
    }

    arctionCustomLightTheme() {
        const mainFont = fontSize => (font) => {
            return font
                .setSize(fontSize)
                .setWeight('normal')
                .setStyle('normal')
                .setFamily('Open Sans');
        };
        const themeDataSeriesFillStyles = [
            new SolidFill({ color: ColorHEX('#ff3925') }),
            new SolidFill({ color: ColorHEX('#009513') }),
            new SolidFill({ color: ColorHEX('#ffa42b') }),
            new SolidFill({ color: ColorHEX('#9b644c') }),
            new SolidFill({ color: ColorHEX('#bf4588') }),
            new SolidFill({ color: ColorHEX('#e6cb00') }),
            new SolidFill({ color: ColorHEX('#0562c0') }),
            new SolidFill({ color: ColorHEX('#f5845a') }),
            new SolidFill({ color: ColorHEX('#4b8b03') }),
            new SolidFill({ color: ColorHEX('#a356a2') }),
        ];
        const mainFontStyle = new SolidFill({ color: ColorHEX('#444444') });
        const themeTextFillStyle = new SolidFill({ color: ColorCSS('#444444') });
        const themeAxisFillStyle = new SolidFill({ color: ColorRGBA(68, 68, 68, 50) });
        const themeUiBackgroundBorderFillStyle = new SolidFill({ color: ColorCSS('#ddd') });

        return customTheme(Themes.lightNew, {
            dashboardSplitterStyle: emptyLine,
            lcjsBackgroundFillStyle: new SolidFill({ color: ColorHEX('#fff') }),
            lcjsBackgroundStrokeStyle: emptyLine,
            panelBackgroundFillStyle: new SolidFill({ color: ColorHEX('#fff') }),
            panelBackgroundStrokeStyle: emptyLine,
            seriesBackgroundFillStyle: new SolidFill({ color: ColorHEX('#fafafa') }),
            seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
            seriesStrokeStyle: (i) => new SolidLine({
                thickness: 1,
                fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
            }),
            customTickGridStrokeStyle: emptyLine,
            axisStyle: new SolidLine({ thickness: 2, highlightThicknessMultiplier: 1, fillStyle: themeAxisFillStyle }),
            axisTitleFont: mainFont(13),
            axisTitleFillStyle: mainFontStyle,
            axisOverlayStyle: new SolidFill({ color: ColorRGBA(68, 68, 68, 0) }),
            uiFont: mainFont(),
            pointMarkerStrokeStyle: new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorCSS('gray') }),
            }),
            pointMarkerFillStyle: new SolidFill({ color: ColorCSS('white') }),
            uiPointableTextBoxFont: mainFont(13),
            uiPointableTextBoxStrokeStyle: new SolidLine({ thickness: 2, fillStyle: themeUiBackgroundBorderFillStyle }),
            uiPointableTextBoxTextFillStyle: themeTextFillStyle,
            //DateTime Axis styling
            dateTimeTickStrategy: Themes.lightNew.dateTimeTickStrategy
                .setGreatTickStyle(emptyTick)
                // .setGreatTickStyle((greatTicks) => greatTicks
                //     .setGridStrokeStyle(emptyLine)
                //     .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                //     .setLabelFont(mainFont(13))
                //     .setLabelFillStyle(new SolidFill({ color: ColorHEX('#b4b4b4') })),
                // )
                .setMajorTickStyle((majorTicks) =>
                    majorTicks
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                )
                .setMinorTickStyle((minorTicks) =>
                    minorTicks
                        .setLabelPadding(this.numericAxisLabelPadding)
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                ),
            //Numeric Axis styling
            numericTickStrategy: Themes.lightNew.numericTickStrategy
                .setMajorTickStyle((majorTicks) =>
                    majorTicks
                        .setLabelPadding(this.numericAxisLabelPadding)
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                )
                .setMinorTickStyle(emptyTick),
            // .setMinorTickStyle((minorTicks) =>
            //     minorTicks
            //         .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
            //         .setGridStrokeStyle(emptyLine)
            //         .setLabelFont(mainFont(10))
            //         .setLabelFillStyle(mainFontStyle),
            // ),

        });
    }

    arctionCustomDarkTheme() {
        const mainFont = fontSize => (font) => {
            return font
                .setSize(fontSize)
                .setWeight('normal')
                .setStyle('normal')
                .setFamily('Open Sans');
        };
        const themeDataSeriesFillStyles = [
            // new SolidFill({ color: ColorCSS('#ffe100') }),
            // new SolidFill({ color: ColorCSS('#858585') }),
            new SolidFill({ color: ColorHEX('#ff3925') }),
            new SolidFill({ color: ColorHEX('#009513') }),
            new SolidFill({ color: ColorHEX('#ffa42b') }),
            new SolidFill({ color: ColorHEX('#9b644c') }),
            new SolidFill({ color: ColorHEX('#bf4588') }),
            new SolidFill({ color: ColorHEX('#e6cb00') }),
            new SolidFill({ color: ColorHEX('#0562c0') }),
            new SolidFill({ color: ColorHEX('#f5845a') }),
            new SolidFill({ color: ColorHEX('#4b8b03') }),
            new SolidFill({ color: ColorHEX('#a356a2') }),
        ];
        const mainFontStyle = new SolidFill({ color: ColorRGBA(255, 255, 255, 224) });
        const themeTextFillStyle = new SolidFill({ color: ColorRGBA(255, 255, 255, 224) });
        const themeAxisFillStyle = new SolidFill({ color: ColorRGBA(255, 255, 255, 120) });
        const themeUiBackgroundBorderFillStyle = new SolidFill({ color: ColorCSS('#ddd') });

        return customTheme(Themes.darkGold, {
            dashboardSplitterStyle: emptyLine,
            lcjsBackgroundFillStyle: new SolidFill({ color: ColorHEX('#252525') }),
            lcjsBackgroundStrokeStyle: emptyLine,
            panelBackgroundFillStyle: new SolidFill({ color: ColorHEX('#252525') }),
            panelBackgroundStrokeStyle: emptyLine,
            seriesBackgroundFillStyle: new SolidFill({ color: ColorHEX('#121212') }),
            // seriesFillStyle: (i) => themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
            seriesStrokeStyle: (i) => new SolidLine({
                thickness: 1,
                fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
            }),
            customTickGridStrokeStyle: emptyLine,
            axisStyle: new SolidLine({ thickness: 2, highlightThicknessMultiplier: 1, fillStyle: themeAxisFillStyle }),
            axisTitleFont: mainFont(13),
            axisTitleFillStyle: mainFontStyle,
            axisOverlayStyle: new SolidFill({ color: ColorRGBA(68, 68, 68, 0) }),
            uiFont: mainFont(),
            pointMarkerStrokeStyle: new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorCSS('gray') }),
            }),
            pointMarkerFillStyle: new SolidFill({ color: ColorCSS('white') }),
            uiPointableTextBoxFont: mainFont(13),
            uiPointableTextBoxStrokeStyle: new SolidLine({ thickness: 2, fillStyle: themeUiBackgroundBorderFillStyle }),
            uiPointableTextBoxTextFillStyle: themeTextFillStyle,

            //DateTime Axis styling
            dateTimeTickStrategy: Themes.lightNew.dateTimeTickStrategy
                // .setGreatTickStyle((greatTicks) => greatTicks
                //     .setGridStrokeStyle(emptyLine)
                //     .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                //     .setLabelFont(mainFont(13))
                //     .setLabelFillStyle(new SolidFill({ color: ColorHEX('#b4b4b4') })),
                // )
                .setGreatTickStyle(emptyTick)
                .setMajorTickStyle((majorTicks) =>
                    majorTicks
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                )
                .setMinorTickStyle((minorTicks) =>
                    minorTicks
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                ),
            //Numeric Axis styling
            numericTickStrategy: Themes.lightNew.numericTickStrategy
                .setMajorTickStyle((majorTicks) =>
                    majorTicks
                        .setLabelPadding(this.numericAxisLabelPadding)
                        .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
                        .setGridStrokeStyle(emptyLine)
                        .setLabelFont(mainFont(13))
                        .setLabelFillStyle(mainFontStyle),
                )
                .setMinorTickStyle(emptyTick),
            // .setMinorTickStyle((minorTicks) =>
            //     minorTicks
            //         .setTickStyle(new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }))
            //         .setGridStrokeStyle(emptyLine)
            //         .setLabelFont(mainFont(10))
            //         .setLabelFillStyle(mainFontStyle),
            // ),
        });
    }

    createDashboard() {
        if (!this.dashboard) {
            this.dashboard = lightningChart({
                license: this.license,
                overrideInteractionMouseButtons: {
                    chartXYPanMouseButton: 0,
                    axisXYPanMouseButton: 0,
                    chartXYRectangleZoomFitMouseButton: 2,
                },
            })
                //https://www.arction.com/lightningchart-js-api-documentation/v3.2.0/interfaces/lightningchart.html#dashboard
                .Dashboard({
                    numberOfColumns: 1,
                    numberOfRows: 1,
                    container: this.id,
                    theme: this.arctionTheme(),
                });

            this.dashboard.setHeight(this.height);
            this.dashboard.setRowHeight(0, 1);
            // this.dashboard.setRowHeight(1, (this.height - 35) / 35);
            // this.dashboard.setRowHeight(1, 6);

        }

        return this.dashboard;
    }

    initTouchZoom() {
        if (!this.chart) return;

        let initialDistance = null;

        const handleTouchStart = (event) => {
            if (event.touches.length === 2) {
                initialDistance = this.getDistance(event.touches[0], event.touches[1]);
            }
        };

        const handleTouchMove = (event) => {
            if (event.touches.length === 2 && initialDistance !== null) {
                event.preventDefault();
                const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
                const scale = initialDistance / currentDistance;
               
                const midX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
                const midY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
                
                this.zoomBothAxesWithTouch(scale, midX, midY);

                initialDistance = currentDistance;
            }
        };

        const handleTouchEnd = () => {
            initialDistance = null;
        };

        this.chart.engine.container.addEventListener('touchstart', handleTouchStart);
        this.chart.engine.container.addEventListener('touchmove', handleTouchMove, { passive: false });
        this.chart.engine.container.addEventListener('touchend', handleTouchEnd);
    }

    getDistance(touch1, touch2) {
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    zoomBothAxesWithTouch(scale, midX, midY) {
        const axisX = this.chart.getDefaultAxisX();
        const rangeX = axisX.getInterval();
        const pixelWidth = this.chart.engine.container.clientWidth;
        const relativeMidX = midX / pixelWidth;
        const dataRangeX = rangeX.end - rangeX.start;
        const newStartX = rangeX.start + (relativeMidX * dataRangeX * (1 - scale));
        const newEndX = rangeX.end - ((1 - relativeMidX) * dataRangeX * (1 - scale));
        axisX.setInterval(newStartX, newEndX, 0, true);

        const pixelHeight = this.chart.engine.container.clientHeight;
        const relativeMidY = 1 - (midY / pixelHeight);
        this.chart.forEachAxisY(axis => {
            const rangeY = axis.getInterval();
            const dataRangeY = rangeY.end - rangeY.start;
            const newStartY = rangeY.start + (relativeMidY * dataRangeY * (1 - scale));
            const newEndY = rangeY.end - ((1 - relativeMidY) * dataRangeY * (1 - scale));
            axis.setInterval(newStartY, newEndY, 0, true);
        });

        this.logCurrentXAxisInterval();
    }

    createChart() {
        if (!this.dashboard) return null;

        const chart = this.dashboard.createChartXY({
            columnIndex: 0,
            rowIndex: 0,
        });

        chart.setPadding({
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        });

        chart.setTitle('');
        chart
            .setMouseInteractionWheelZoom(false)
            .setMouseInteractionRectangleFit(true);

        chart
            .getDefaultAxisX()
            .disableAnimations()
            .setTickStrategy(AxisTickStrategies.DateTime, tickStrategy =>
                tickStrategy.setDateOrigin(this.dateOrigin)
            )
            .setAxisInteractionZoomByWheeling(false)
            .setAxisInteractionZoomByDragging(false)
            .setNibInteractionScaleByWheeling(false)
            .setNibInteractionScaleByDragging(false)
            .setAxisMousePanStyle(MouseStyles.Point)
            .setAxisMouseHoverStyle(MouseStyles.Point);

        chart.getDefaultAxisY().dispose();
        chart.setAutoCursor(autoCursor => {
            autoCursor.disposeResultTable();
        });
        this.chart = chart;
        this.initMouseWheelZoom();
        this.initTouchZoom();
        this.isFirstClick = false;
        this.isDblClickHandled = false;

        const handleMouseEvent = (event) => {
            const eventType = event.type;

            if (eventType === 'contextmenu') {
                this.logCurrentXAxisInterval();
                this.isFirstClick = true;
                this.stopInterval();
                return;
            }

            if (eventType === 'click') {
                this.logCurrentXAxisInterval();
                this.isFirstClick = true;
                this.stopInterval();
                return;
            }

            if (eventType === 'dblclick') {
                this.isDblClickHandled = true;
                this.isFirstClick = false;
                this.logEndPointAsUTC();
                this.startInterval();
                return;
            }
    
            if (eventType === 'wheel') {
                this.logCurrentXAxisInterval();
                this.isFirstClick = true;
                this.stopInterval();
                return;
            }
    
            if (this.isFirstClick) {
                this.logCurrentXAxisInterval();
                return;
            }
    
            if (this.isDblClickHandled) {
                this.logEndPointAsUTC();
                return;
            }
        };
    
        ['mousemove', 'click', 'contextmenu', 'dblclick', 'wheel'].forEach(eventType => {
            chart.engine.container.addEventListener(eventType, handleMouseEvent);
        });
    
        return chart;
    }
    
    

    addYAxis({ opposite, title }) {
        if (!this.chart) return null;

        return this.chart
            .addAxisY({ opposite })
            // .setScrollStrategy(AxisScrollStrategies.expansion)
            .setTitle(title)
            .setInterval(-1, 1)
            .setAxisInteractionZoomByWheeling(false)
            .setAxisInteractionZoomByDragging(false)
            .setNibInteractionScaleByWheeling(false)
            .setNibInteractionScaleByDragging(false)
            .setAxisMousePanStyle(MouseStyles.Point)
            .setAxisMouseHoverStyle(MouseStyles.Point)
            .disableAnimations();
    }

    createLegendBoxPanel() {
        if (!this.dashboard) return null;
        return this.dashboard.createLegendBoxPanel({ columnIndex: 0, rowIndex: 0 });
    }

    createHiddenLegend(chart) {
        if (!chart) return null;
        const hiddenLegend = chart
            .addLegendBox(LegendBoxBuilders.VerticalLegendBox)
            .setTitle('')
            .setOrigin(UIOrigins.LeftTop)
            .setPosition({ x: 0, y: 50 });

        hiddenLegend.add(chart);
        hiddenLegend.setEntries((entry, component) => {
            const lineStyle = component.getStrokeStyle().getFillStyle();
            entry
                .setTextFillStyle(lineStyle)
                .setTextFont((font) => font.setStyle('normal'),
                    // .setWeight('bold'),
                );
        });
        return hiddenLegend;
    }

    createLegendLayout(chart) {
        if (!chart) return;

        return chart.addUIElement(UILayoutBuilders.Column)
            .setBackground(b => b.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
            .setOrigin(UIOrigins.LeftTop)
            .setPosition({ x: 0, y: 100 })
            .setPadding(0)
            .setMargin(0);
    }

    getLegendEntries(legend) {
        const items = [];
        legend.setEntries((entry, component) => {
            if (entry.isDisposed()) {
                entry = undefined;
                return;
            }
            const { size, family } = entry.getTextFont();
            const entryWidth = pixelWidth(entry.getText(), { size, font: family.toLowerCase() });
            items.push({
                item: component,
                width: entryWidth + 20,
            });
            // items.push(entry)
        });

        return items;
    }

    legendEntriesChunks(arr, max) {
        const chunks = [];

        let currentChunkWidth = 0;
        let currentStartIndex = 0;


        arr.forEach((value, currentIndex) => {
            const isLast = currentIndex === (arr.length - 1);

            if (value.width >= max) {
                chunks.push([value]);
                currentStartIndex += 1;
            } else {
                currentChunkWidth += value.width;
                if (!isLast) {
                    if ((currentChunkWidth + arr[currentIndex + 1].width) >= max) {
                        chunks.push(arr.slice(currentStartIndex, currentIndex + 1));
                        currentStartIndex = currentIndex + 1;
                        currentChunkWidth = 0;
                    }
                } else {
                    chunks.push(arr.slice(currentStartIndex));
                }
            }
        });
        return chunks;
    }

    generateAdaptiveLegend(legend, legendLayout, chart) {
        const items = this.getLegendEntries(legend);
        let chartWidth = 400;
        try {
            const chartSize = chart.uiScale.getInnerIntervalPixels();
            chartWidth = chartSize.x - 55;
        } catch (e) {
            console.log(e);
        }

        const itemsChunks = this.legendEntriesChunks(items, chartWidth);
        chart.setPadding({ top: itemsChunks.length * 40 });

        itemsChunks.forEach(chunk => {
            const rowLegend = legendLayout
                .addElement(LegendBoxBuilders.HorizontalLegendBox)
                .setTitle('')
                .setPadding(0)
                .setMargin(0);
            chunk.forEach(entry => {
                rowLegend.add(entry.item);
            });
            rowLegend.setEntries((entry, component) => {
                const lineStyle = component.getStrokeStyle().getFillStyle();
                entry
                    .setTextFillStyle(lineStyle)
                    .setTextFont((font) => font.setStyle('normal'),
                        // .setWeight('bold'),
                    );
            });
        });
    }


}


// const themeDataSeriesFillStylesLight = [
//     new SolidFill({ color: ColorHEX('#ff3925') }),
//     new SolidFill({ color: ColorHEX('#009513') }),
//     new SolidFill({ color: ColorHEX('#ffa42b') }),
//     new SolidFill({ color: ColorHEX('#9b644c') }),
//     new SolidFill({ color: ColorHEX('#bf4588') }),
//     new SolidFill({ color: ColorHEX('#e6cb00') }),
//     new SolidFill({ color: ColorHEX('#0562c0') }),
//     new SolidFill({ color: ColorHEX('#f5845a') }),
//     new SolidFill({ color: ColorHEX('#4b8b03') }),
//     new SolidFill({ color: ColorHEX('#a356a2') }),
// ];
//
// const themeDataSeriesFillStylesDark = [
//     new SolidFill({ color: ColorHEX('#ff3925') }),
//     new SolidFill({ color: ColorHEX('#009513') }),
//     new SolidFill({ color: ColorHEX('#ffa42b') }),
//     new SolidFill({ color: ColorHEX('#9b644c') }),
//     new SolidFill({ color: ColorHEX('#bf4588') }),
//     new SolidFill({ color: ColorHEX('#e6cb00') }),
//     new SolidFill({ color: ColorHEX('#0562c0') }),
//     new SolidFill({ color: ColorHEX('#f5845a') }),
//     new SolidFill({ color: ColorHEX('#4b8b03') }),
//     new SolidFill({ color: ColorHEX('#a356a2') }),
// ];
//
// const darkThemeChartColors = [
//     '#ff3925',
//     '#009513',
//     '#ffa42b',
//     '#9b644c',
//     '#bf4588',
//     '#e6cb00',
//     '#0562c0',
//     '#f5845a',
//     '#4b8b03',
//     '#a356a2',
// ]

export {
    arctionHelper,
    ArctionDashboard,
};