import Api from 'Services/Api.service';
import Qs from 'qs';
import { apiAuthBaseUrl, apiBaseUrl } from 'config';
import { appLangCode, timeServerToLocal } from 'helpers';

class DmsApiService {

    getSettings = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/settings`);
        } catch (e) {
            console.log('error while fetching user settings');
        }
    };

    getThemeList = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/settings/themes`);
        } catch (e) {
            console.log('error while fetching user themes');
        }
    };

    // setTheme = async (winId, theme) => {
    //     try {
    //         await Api.post(`${apiAuthBaseUrl}/settings/themes`, {
    //             ParentTabId: winId,
    //             Theme: theme
    //         })
    //     } catch (e) {
    //         console.log('error while set user theme')
    //     }
    // }

    getLanguageList = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/settings/languages`);
        } catch (e) {
            console.log('error while fetching user languages');
        }
    };

    setLanguage = async (winId, language) => {
        try {
            await Api.post(`${apiAuthBaseUrl}/settings/languages`, {
                ParentTabId: winId,
                language: language,
            });
        } catch (e) {
            console.log('error while set user language');
        }
    };

    setTheme = async (winId, theme) => {
        try {
            await Api.post(`${apiAuthBaseUrl}/settings/themes`, {
                ParentTabId: winId,
                Theme: theme,
            });
        } catch (e) {
            console.log('error while set user theme');
        }
    };

    closeWindow = async id => {
        try {
            await Api.post(`${apiAuthBaseUrl}/tabs/${id}/close`);
        } catch (e) {
            throw Error('Fetch Jobs Error');
        }
    };

    getCompanies = async () => {
        try {
            const response = await Api.get(`${apiAuthBaseUrl}/companies`);
            return response.data;
        } catch (e) {
            throw Error('Fetch Companies Error');
        }
    };

    getJobs = async () => {
        try {
            const response = await Api.get(`${apiBaseUrl}/jobs`);
            return this._transformJobs(response.data);
        } catch (e) {
            throw Error('Fetch Jobs Error');
        }
    };

    getLicenses = async () => {
        try {
            const response = await Api.get(`${apiBaseUrl}/jobs/licenses`);
            return (response.data);
        } catch (e) {
            throw Error('Fetch Licenses Error');
        }
    };

    getJobsShortPaged = async (skip, take, licenseIds, searchTerm) => {
        try {
          let queryParams = `skip=${skip}&take=${take}&searchTerm=${searchTerm}`;
          if (licenseIds && licenseIds.length) {
            licenseIds.forEach((id) => {
              queryParams += `&licenseIds=${id}`;
            });
          }
          const response = await Api.get(`${apiBaseUrl}/jobs/short/paged?${queryParams}`);
          return response.data;
        } catch (e) {
          throw Error("Error while fetching paged jobs");
        }
      };
      

    getJobsShort = async () => {
        try {
            const response = await Api.get(`${apiBaseUrl}/jobs/short`);
            return response.data;
        } catch (e) {
            throw Error('Fetch Jobs Error');
        }
    };

    getUniqueUnits = async () => {
        try {
            const response = await Api.get(`${apiBaseUrl}/units/unique`);
            return response.data;
        } catch (e) {
            throw Error('Fetch Units Error');
        }
    };

    getJobsById = async id => {
        try {
            const response = await Api.get(`${apiBaseUrl}/jobs/${id}`);
            return this._transformJobs([response.data])[0];
        } catch (e) {
            throw Error('Fetch Jobs Error');
        }
    };

    getChannelsData = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/data/by-custom-channels`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getChannelsDataInitWithFilters = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/data/jobs/by-custom-channels/initialize/with-filters`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getChannelsDataInit = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/data/by-custom-channels/initialize`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getJobsChannelsDataInit = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/data/jobs/by-custom-channels/initialize`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getLastChannelsData = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/Data/by-custom-channels/last`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };


    // getChannelsData = async (channelIds) => {
    //     try {
    //         const response = await Api.get(`data`, {
    //             params: {channelIds: channelIds},
    //             paramsSerializer: function (params) {
    //                 return Qs.stringify(params, {arrayFormat: 'repeat'})
    //             }
    //         })
    //         return response.data
    //     } catch (e) {
    //         throw Error('Fetch Channels Data Error')
    //     }
    // }

    getChannelsDataByDaterange = async (from, to, channelIds) => {
        try {
            const response = await Api.get(`${apiBaseUrl}/data/by-daterange/${from}/${to}`, {
                params: { channelIds: channelIds },
                paramsSerializer: function (params) {
                    return Qs.stringify(params, { arrayFormat: 'repeat' });
                },
            });
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getChannelsDataByCustomAxis = async (channelsData) => {
        try {
            const response = await Api.post(`${apiBaseUrl}/data/by-custom-axis`, channelsData);
            return response.data;
        } catch (e) {
            throw Error('Fetch Channels Data Error');
        }
    };

    getItemTypes = async (lang) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/items/types`, {
                headers: {
                    'Accept-Language': appLangCode(lang),
                },
            });
        } catch (e) {
            throw Error('Fetch Item Types Error');
        }
    };

    syncTabWithServer = async (tabId, items, colors, companyId) => {
        try {

            return await Api.post(`${apiAuthBaseUrl}/tabs/items`, {
                tabId,
                items,
                time: new Date(),
                colors,
                companyId,
            }, {
                headers: {
                    'CompanyId': companyId,
                },
            });
        } catch (e) {
            throw Error('sync tab error');
        }
    };

    getTabColors = async (tabId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/tabs/${tabId}/colors`);
        } catch (e) {
            throw Error('Fetch Item Types Error');
        }
    };

    getTabColorsByCompanyId = async (tabId, companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/tabs/${tabId}/colors/by-companyid/${companyId}`);
        } catch (e) {
            throw Error('Fetch Item Types Error');
        }
    };

    postItem = async (tabId, item) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/tabs/${tabId}/items`, item);
        } catch (e) {
            throw Error('Putting Item Error');
        }
    };

    updateItem = async (tabId, itemId, item) => {
        try {
            return await Api.put(`${apiAuthBaseUrl}/tabs/${tabId}/items/${itemId}`, item);
        } catch (e) {
            throw Error('Updating Item Error');
        }
    };

    removeItem = async (tabId, itemId) => {
        try {
            return await Api.delete(`${apiAuthBaseUrl}/tabs/${tabId}/${itemId}`);
        } catch (e) {
            throw Error('Removing Item Error');
        }
    };

    _transformJobs = jobs => {
        if (!jobs)
            return [];
        // console.time('TransformJobs')
        // console.log(transformed)
        return jobs.map(job => {
            const units = job.units.map(unit => {
                const channels = unit.channels.map(channel => {
                    return {
                        ...channel,
                        jobId: job.id,
                        unitId: unit.id,
                        unitName: unit.nameLocalized,
                        unitCode: unit.code,
                        defaultUnit: channel.unit,
                    };
                });
                return {
                    ...unit,
                    channels,
                    jobId: job.id,
                };
            });

            return {
                ...job,
                startTime: this._fixTimeFormat(timeServerToLocal(job.startTime, job.timeZone)),
                stopTime: this._fixTimeFormat(timeServerToLocal(job.stopTime, job.timeZone)),
                units,
            };
        });
    };

    getTemplatesByCompanyId = async (id) => {
        try {
            return await Api.get(`${apiBaseUrl}/templates/by-companyid/${id}`);
        } catch (e) {
            throw e;
        }
    };

    getTemplatesByJobId = async (id) => {
        try {
            return await Api.get(`${apiBaseUrl}/templates/by-jobid/${id}`);
        } catch (e) {
            throw e;
        }
    };

    getTemplates = async () => {
        try {
            return await Api.get(`${apiBaseUrl}/templates`);
        } catch (e) {
            throw e;
        }
    };


    saveTemplate = async (tabId, name, templateId = null) => {
        try {
            return await Api.post(`${apiBaseUrl}/templates/save`, {
                tabId,
                templateId,
                name,
            });
        } catch (e) {
            throw e;
        }
    };

    loadTemplate = async (templateId, jobId, tabId) => {
        try {
            return await Api.post(`${apiBaseUrl}/templates/load`, {
                templateId,
                jobId,
                tabId,
            });
        } catch (e) {
            throw e;
        }
    };

    deleteTemplate = async (id) => {
        try {
            return await Api.delete(`${apiBaseUrl}/templates/${id}`);
        } catch (e) {
            throw e;
        }
    };

    getUsers = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/users`);
        } catch (e) {
            throw e;
        }
    };


    createUser = async (userInfo) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/users`, userInfo);
        } catch (e) {
            throw e;
        }
    };

    updateUser = async (userInfo) => {
        try {
            return await Api.put(`${apiAuthBaseUrl}/users`, userInfo);
        } catch (e) {
            throw e;
        }
    };

    deleteUser = async (id) => {
        try {
            return await Api.delete(`${apiAuthBaseUrl}/Users/${id}`);
        } catch (e) {
            throw e;
        }
    };

    getOneJobPasswords = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/JobPasswords`);
        } catch (e) {
            throw e;
        }
    };

    getOneJobPasswordLink = async (id) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/JobPasswords/${id}/link`);
        } catch (e) {
            throw e;
        }
    };

    createOneJobPassword = async (jobId, companyId) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/JobPasswords`, { jobId: jobId, companyId: companyId });
        } catch (e) {
            throw e;
        }
    };

    updateOneJobPassword = async (data) => {
        try {
            return await Api.put(`${apiAuthBaseUrl}/JobPasswords`, data);
        } catch (e) {
            throw e;
        }
    };

    updateIsEnabledOneJobPassword = async (id, isEnabled) => {

        try {
            const suffix = isEnabled ? 'disable' : 'enable';
            return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/${suffix}`);
            // if (isEnabled) {
            //     return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/disable`)
            // }
            // else
            //     return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/enable`)
        } catch (e) {
            throw e;
        }
    };

    // updateIsEnabledOneJobPassword = async (id, isEnabled) => {
    //
    //     try {
    //         const suffix = isEnabled ? 'disable' : 'enable'
    //         return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/${suffix}`)
    //         // if (isEnabled) {
    //         //     return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/disable`)
    //         // }
    //         // else
    //         //     return await Api.put(`${apiAuthBaseUrl}/jobpasswords/${id}/enable`)
    //     } catch (e) {
    //         throw e
    //     }
    // }

    deleteOneJobPassword = async (id) => {
        try {
            return await Api.delete(`${apiAuthBaseUrl}/JobPasswords/${id}`);
        } catch (e) {
            throw e;
        }
    };

    resendUserEmail = async (email) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/Users/email/resend`, { email: email });
        } catch (e) {
            throw e;
        }
    };

    getPasswordChangeToken = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/Users/password/change`);
        } catch (e) {
            throw e;
        }
    };

    getLicenseIds = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/licenses`);
        } catch (e) {
            throw e;
        }
    };

    getLicenseIdsByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/licenses/by-companyid/${companyId}`);
        } catch (e) {
            throw e;
        }
    };

    getCompany = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/companies/${companyId}`);
        } catch (e) {
            throw e;
        }
    };

    getCompanyImage = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/companies/${companyId}/with-image`);
        } catch (e) {
            throw e;
        }
    };

    uploadCompanyImage = async (companyId, file) => {
        try {
            let fd = new FormData();
            fd.append('file', file);
            if (companyId)
                return await Api.post(`${apiAuthBaseUrl}/companies/${companyId}/image`, fd);
            else
                return await Api.post(`${apiAuthBaseUrl}/companies/image`, fd);
        } catch (e) {
            throw e;
        }
    };

    updateCompany = async (company) => {
        try {
            return await Api.put(`${apiAuthBaseUrl}/companies`, company);
        } catch (e) {
            throw e;
        }
    };

    getEvents = async (jobId) => {
        try {
            return await Api.get(`${apiBaseUrl}/events/by-jobid/${jobId}`);
        } catch (e) {
            throw e;
        }
    };

    getJobProperties = async (jobId) => {
        try {
            return await Api.get(`${apiBaseUrl}/JobProperties/by-jobid/${jobId}`);
        } catch (e) {
            throw e;
        }
    };

    exportData = async (data) => {
        const fetchUrl = data.jobId
            ? `${apiBaseUrl}/DataExport/jobs`
            : `${apiBaseUrl}/DataExport/jobs/by-custom-channels`;
        try {
            return await Api.post(fetchUrl, data);
        } catch (e) {
            throw e;
        }
    };

    exportEventLoggerData = async (data) => {
        try {
            return await Api.post(`${apiBaseUrl}/DataExport/jobs/events`, data);
        } catch (e) {
            throw e;
        }
    };

    exportUnitsData = async (data) => {
        const fetchUrl = !data.channels
            ? `${apiBaseUrl}/DataExport/units`
            : `${apiBaseUrl}/DataExport/units/by-custom-channels`;
        try {
            return await Api.post(fetchUrl, data);
        } catch (e) {
            throw e;
        }
    };

    exportUnitsEventLoggerData = async (data) => {
        try {
            return await Api.post(`${apiBaseUrl}/DataExport/units/events`, data);
        } catch (e) {
            throw e;
        }
    };

    getExportDateTypes = async () => {
        try {
            return await Api.get(`${apiBaseUrl}/DataExport/types`);
        } catch (e) {
            throw e;
        }
    };

    getCompanyJobsShort = async (companyId) => {
        try {
            const response = await Api.get(`${apiBaseUrl}/jobs/short`, {
                headers: {
                    'CompanyId': companyId,
                },
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    };

    getOneJobPasswordsByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/JobPasswords/by-companyid/${companyId}`, {
                headers: {
                    'CompanyId': companyId,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    getUsersByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/users/by-companyid/${companyId}`, {
                headers: {
                    'CompanyId': companyId,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    getUnits = async () => {
        try {
            return await Api.get(`${apiBaseUrl}/units/unique-by-uid`);
        } catch (e) {
            throw e;
        }
    };

    getIntegrationTypes = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/integrations/options/integration-types`)
        } catch (e) {
            throw e
        }
    }

    getIntegrationDateIntervals = async () => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/integrations/options/date-intervals`)
        } catch (e) {
            throw e
        }
    }

    getIntegrations = async (companyId) => {
        try {
            return await Api.get(`${apiAuthBaseUrl}/integrations`, {
                headers: {
                    'CompanyId': companyId,
                },
            });
        } catch (e) {
            throw e
        }
    }

    createIntegration = async (integrationInfo) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/integrations`, integrationInfo);
        } catch (e) {
            throw e;
        }
    };

    testIntegration = async (integrationInfo) => {
        try {
            return await Api.post(`${apiAuthBaseUrl}/integrations/emaint/test-connection`, integrationInfo);
        } catch (e) {
            throw e;
        }
    };

    editIntegration = async (integrationInfo) => {
        try {
            return await Api.put(`${apiAuthBaseUrl}/integrations`, integrationInfo);
        } catch (e) {
            throw e;
        }
    };

    deleteIntegration = async (id) => {
        try {
            return await Api.delete(`${apiAuthBaseUrl}/integrations/${id}`, {
                
            });
        } catch (e) {
            throw e;
        }
    };

    getIntegrationLogs = async (id, from, to) => {
        try {
          const params = {};
    
          if (from) {
            params.from = from.toISOString();
          }
    
          if (to) {
            params.to = to.toISOString();
          }
    
          return await Api.get(`${apiAuthBaseUrl}/Integrations/${id}/logs`, { params });
        } catch (e) {
          console.error('Error in getIntegrationLogs:', e);
          throw e;
        }
      };

    _fixTimeFormat = str => str ? str.replace('T', ' ') : null;

}

export default new DmsApiService();
